<!-- 团配置页面 -->
<template>
  <div class="groupConfig">
    <Tabs v-model="tabVal" :animated="false">
      <TabPane label="参团配置" name="1">
        <div class="config-content">
          <Form :model="formData1" :label-width="130">
            <FormItem label="截止参团时间：">
              <Input v-model="formData1.defaultRegimentUpToTime" style="width:400px"></Input>
              <p style="color:#c0c4cc">提前多少时间截止参团 (单位分钟)</p>
            </FormItem>
            <FormItem label="团退出手续费：">
              <div class="clr">
                <Table class="fl" border :columns="columns" :data="dataList" width="600">
                  <template slot-scope="{ row ,index}" slot="explanation">
                    <Input v-model="dataList[index].explanation"></Input>
                  </template>
                  <template slot-scope="{ row ,index }" slot="refundSectionTime">
                    <Input v-model="dataList[index].refundSectionTime"></Input>
                  </template>
                  <template slot-scope="{ row ,index }" slot="sectionTime">
                    <Input v-model="dataList[index].sectionTime"></Input>
                  </template>
                  <template slot-scope="{ row ,index }" slot="refundServiceCharge">
                    <Input v-model="dataList[index].refundServiceCharge"></Input>
                  </template>
                </Table>
              </div>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
      <TabPane label="团提示配置" name="2">
        <div class="config-content">
          <Form :model="formData2" :label-width="130">
            <FormItem label="发送模版：">
              <div class="clr">
                <Table class="fl" border :columns="columns2" :data="dataList2" width="600">
                  <template slot-scope="{ row }" slot="type1">
                    <Input v-model="row.type1"></Input>
                  </template>
                  <template slot-scope="{ row }" slot="type2">
                    <Input v-model="row.type2"></Input>
                  </template>
                </Table>
                <Button class="fl" style="margin-left:10px" @click="add(2)">新增</Button>
              </div>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="save">提交</Button>
            </FormItem>
          </Form>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { getRegimentConfig, editRegimentConfig } from '@/api/memberCenter.js'

export default {
  data() {
    return {
      tabVal: '1',
      formData1: {
        defaultRegimentUpToTime: "",
      },
      formData2: {
      },
      columns: [
        {
          title: '说明',
          key: 'explanation',
          slot: 'explanation'
        },
        {
          title: '订单退票区间时间 (分)',
          key: 'refundSectionTime',
          slot: 'refundSectionTime'
        },
        {
          title: '区间时间',
          key: 'sectionTime',
          width: 100,
          slot: 'sectionTime'
        },
        {
          title: '退票手续费',
          key: 'refundServiceCharge',
          slot: 'refundServiceCharge',
          width: 100,
        },
      ],
      dataList: [
        // {
        //   id: '',
        //   explanation: '开团前1440分钟前退团 (24小时)',
        //   refundSectionTime: '0',
        //   sectionTime: '1440',
        //   refundServiceCharge: '0%',
        //   updateTime: '',
        //   createTime: '',
        //   remark: '',
        // }
      ],
      columns2: [
        {
          title: '团类型',
          key: 'type1',
          width: 200,
          slot: "type1"
        },
        {
          title: '团提示语',
          key: 'type2',
          slot: "type2"
        },
      ],
      dataList2: [
        {
          type1: '默认',
          type2: '听从团长安排，由团长保证活动所属场地安全和秩序',
        },
        {
          type1: 'KTV',
          type2: '听从团长安排，由团长保证活动所属场地安全和秩序',
        },
        {
          type1: '酒吧',
          type2: '少喝酒',
        },
        {
          type1: '轰趴别墅',
          type2: '玩得开心',
        },
      ]
    };
  },
  created() {
    this.getRegimentConfig()
  },
  methods: {
    getRegimentConfig() {
      getRegimentConfig().then(res => {
        if (res.success) {
          this.formData1.defaultRegimentUpToTime = res.result.defaultRegimentUpToTime
          this.dataList = JSON.parse(JSON.stringify(res.result.sysRegiment))
        }
      })
    },
    save() {
      if (this.tabVal == 1) {
        let params = {}
        params.defaultRegimentUpToTime = this.formData1.defaultRegimentUpToTime
        params.sysRegiment = this.dataList
        editRegimentConfig(params).then(res => {
          if (res.success) {
            this.$Message.success('保存成功！')
          }
        })
      }
    },
    add(type) {
      if (type == 1) {
        this.dataList.push({
          explanation: '',
          refundSectionTime: '',
          sectionTime: '',
          refundServiceCharge: '',
        })
      } else {
        this.dataList2.push({
          type1: '',
          type2: ''
        })
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.groupConfig {
  height: calc(100vh - 150px);
  .config-content {
    height: calc(100vh - 200px);
    padding-top: 10px;
    overflow: auto;
  }
}
</style>